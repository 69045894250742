import { QuestionIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/button';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

export default function HelpButton() {
    return (
        <Menu>
            <MenuButton as={IconButton} variant="ghost" icon={<QuestionIcon />} />
            <MenuList>
                <MenuItem onClick={() => window.open('https://wiki.jexo.io/space/GOOS', '_blank')}>
                    Documentation
                </MenuItem>
                <MenuItem onClick={() => window.open('https://discord.gg/GNkWSDMsrK', '_blank')}>Feedback</MenuItem>
            </MenuList>
        </Menu>
    );
}
