import { Flex } from '@chakra-ui/react';
import LogoFooterSvg from './LogoFooterSvg';
import { useRouter } from 'next/router';

export default function LogoFooter() {
    const router = useRouter();

    return (
        <Flex size="lg" p={2} cursor="pointer" onClick={() => router.push('/')}>
            <LogoFooterSvg width="100px" height="32px" />
        </Flex>
    );
}
