import { Button, IconButton } from '@chakra-ui/button';
import { Flex, Spacer } from '@chakra-ui/layout';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import AccountButton from './AccountButton';
import ThemeSwitcher from './ThemeSwitcher';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import LogoHeading from 'components/Logo/LogoHeading';
import { signIn, useSession } from 'next-auth/react';
import { LightButton, RainbowButton } from 'components/Buttons';
import HelpButton from './HelpButton';

export default function NavBar() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray', 'white');

    const toast = useToast();
    const router = useRouter();

    const callbackLink = router.asPath.includes('discover')
        ? `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/my`
        : `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/${router.asPath}`;

    const onCreate = async () => {
        try {
            const response = await axios.post('/api/roadmaps');
            router.push(`/${response.data.id}/edit`);
        } catch (error) {
            toast({
                title: 'Could not create Roadmap',
                description: (error as Error).message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const { data: session } = useSession();

    return (
        <Flex
            direction={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            px={5}
            justifyContent="space-between"
        >
            <Flex
                width={{ base: '100%', lg: 'auto' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mr={16}
            >
                <LogoHeading />
                <IconButton
                    display={{ base: 'block', lg: 'none' }}
                    variant="ghost"
                    icon={isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
                    onClick={() => setMenuOpen(!isMenuOpen)}
                    aria-label="Menu"
                />
            </Flex>
            <Flex
                flex={[0, 0, 1]}
                direction={{ base: 'column', lg: 'row' }}
                alignItems={{ base: 'flex-start', lg: 'center' }}
                display={{ base: isMenuOpen ? 'flex' : 'none', lg: 'flex' }}
                zIndex={1}
                bg={bgColor}
                w={{ base: '100%', lg: 'auto' }}
                border={{ base: `1px solid ${borderColor}`, lg: 'none' }}
                borderRadius="md"
            >
                <Flex
                    align="center"
                    width={{ base: '100%', lg: 'auto' }}
                    direction={{ base: 'column', lg: 'row' }}
                    flex={{ base: 0, lg: 1 }}
                >
                    {session && (
                        <>
                            <Button
                                size="sm"
                                variant={router.pathname === '/my' ? 'solid' : 'ghost'}
                                onClick={() => router.push('/my')}
                                mr={4}
                            >
                                My roadmaps
                            </Button>
                            <Button
                                size="sm"
                                variant={router.pathname === '/shared' ? 'solid' : 'ghost'}
                                onClick={() => router.push('/shared')}
                                mr={4}
                            >
                                Shared with me
                            </Button>
                        </>
                    )}
                    <Button
                        size="sm"
                        variant={router.pathname === '/discover' ? 'solid' : 'ghost'}
                        onClick={() => router.push('/discover')}
                    >
                        Discover roadmaps ✨
                    </Button>
                </Flex>
                <Flex width={{ base: '100%', lg: 'auto' }} direction={{ base: 'column', lg: 'row' }} align="center">
                    {session ? (
                        <>
                            <RainbowButton size="sm" onClick={onCreate}>
                                CREATE NEW ROADMAP
                            </RainbowButton>
                            <Spacer w={2} />
                            <ThemeSwitcher />
                            <Spacer w={2} />
                            <HelpButton />
                            <Spacer w={2} />
                            <AccountButton />
                        </>
                    ) : (
                        <>
                            <LightButton size="sm" onClick={() => signIn()}>
                                SIGN IN
                            </LightButton>
                            <Spacer w={2} />
                            <RainbowButton
                                size="sm"
                                onClick={() => router.push(`/auth/signup?callbackUrl=${callbackLink}`)}
                            >
                                JOIN
                            </RainbowButton>
                            <Spacer w={2} />
                            <ThemeSwitcher />
                            <Spacer w={2} />
                            <HelpButton />
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}
