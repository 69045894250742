import { Avatar } from '@chakra-ui/avatar';
import { IconButton } from '@chakra-ui/button';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function AccountButton() {
    const { data } = useSession();
    const router = useRouter();

    return (
        <Menu>
            <MenuButton as={IconButton} variant="link">
                <Avatar name={data?.user?.name || ''} size="sm" src={data?.user?.image || ''} />
            </MenuButton>
            <MenuList>
                {data && (
                    <>
                        <MenuItem onClick={() => router.push('/account/profile')}>Account settings</MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                evt.preventDefault();
                                signOut({ callbackUrl: `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}` });
                            }}
                        >
                            Sign out
                        </MenuItem>
                    </>
                )}
                {!data && (
                    <MenuItem
                        onClick={(evt) => {
                            evt.preventDefault();
                            signIn();
                        }}
                    >
                        Sign In
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    );
}
