import Head from 'next/head';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { PropsWithChildren, ReactElement } from 'react';
import NavBar from './NavBar/NavBar';
import { DEFAULT_ROADMAP_GRADIENT } from 'lib/constants';
import Footer from './Footer/Footer';

type LayoutProps = {
    breadcrumbs?: string[];
    title?: string;
    noBox?: boolean;
    noNavBar?: boolean;
    noFooter?: boolean;
    navBar?: ReactElement;
};

export default function Layout({
    children,
    title,
    noBox = false,
    noNavBar = false,
    noFooter = false,
    navBar,
}: PropsWithChildren<LayoutProps>) {
    const navbarBg = useColorModeValue('white', 'gray.800');
    return (
        <>
            <Head>
                <title>{title && `${title} |`} Goosly by Jexo</title>
            </Head>
            {!noNavBar && (
                <Flex
                    as="header"
                    position="fixed"
                    w="100%"
                    top="0"
                    direction="column"
                    boxShadow="xs"
                    h="52px"
                    zIndex="200"
                    bgColor={navbarBg}
                >
                    {navBar || <NavBar />}
                    <Flex
                        h="4px"
                        bgGradient={`linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end}, ${DEFAULT_ROADMAP_GRADIENT.green})`}
                    />
                </Flex>
            )}
            <Box mt={noNavBar ? 0 : '52px'} p={noBox ? 0 : 4}>
                {children}
            </Box>
            {!noFooter && <Footer />}
        </>
    );
}
