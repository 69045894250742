import { Flex } from '@chakra-ui/react';
import LogoHeadingSvg from './LogoHeadingSvg';
import { useRouter } from 'next/router';

export default function LogoHeading() {
    const router = useRouter();
    return (
        <Flex size="lg" p={2} cursor="pointer" onClick={() => router.push('/public-roadmaps')}>
            <LogoHeadingSvg width="100px" height="32px" />
        </Flex>
    );
}
