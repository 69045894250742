import { Text, Link } from '@chakra-ui/react';

interface FooterLinkProps {
    title: string;
    href: string;
}

export default function FooterLink({ title, href }: FooterLinkProps) {
    return (
        <Link href={href}>
            <Text fontSize="xs" lineHeight="4" fontWeight="semibold" textDecoration="underline">
                {title}
            </Text>
        </Link>
    );
}
