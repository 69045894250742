import { Button, ButtonProps, IconButton, IconButtonProps } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/react';
import { DEFAULT_ROADMAP_GRADIENT } from 'lib/constants';
import { forwardRef, Ref } from 'react';

export const DarkButton = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const bgColor = useColorModeValue('black', 'white');
    const hoverBgColor = useColorModeValue('gray.700', 'gray.300');
    const activeBgColor = useColorModeValue('gray.600', 'gray.400');
    const color = useColorModeValue('white', 'black');

    return (
        <Button
            ref={ref}
            bgColor={bgColor}
            color={color}
            _hover={{ backgroundColor: hoverBgColor }}
            _active={{ backgroundColor: activeBgColor }}
            {...props}
        />
    );
});
DarkButton.displayName = 'DarkButton';

export const IconDarkButton = forwardRef((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => (
    <IconButton
        ref={ref}
        bgColor="black"
        color="white"
        _hover={{ backgroundColor: 'gray.700' }}
        _active={{ backgroundColor: 'gray.600' }}
        {...props}
    />
));
IconDarkButton.displayName = 'IconDarkButton';

export const LightButton = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button ref={ref} variant="ghost" border="1px" {...props} />
));
LightButton.displayName = 'LightButton';

export const IconLightButton = forwardRef((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => (
    <IconButton ref={ref} variant="ghost" border="1px" {...props} />
));
IconLightButton.displayName = 'IconLightButton';

export const RainbowButton = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
        ref={ref}
        color="white"
        bgGradient={`linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end})`}
        _hover={{
            bgGradient: `linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end})`,
        }}
        _active={{
            bgGradient: `linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end})`,
        }}
        {...props}
    />
));
RainbowButton.displayName = 'RainbowButton';
