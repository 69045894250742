import { Box, Flex, Grid, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react';
import LogoFooter from 'components/Logo/LogoFooter';
import FooterLink from 'components/pages/home/FooterLink';
import FooterMenu from 'components/pages/home/FooterMenu';
import { DEFAULT_ROADMAP_GRADIENT } from 'lib/constants';
import { AiOutlineTwitter, AiOutlineInstagram, AiFillLinkedin, AiFillFacebook, AiFillYoutube } from 'react-icons/ai';

const pagesArray = [
    { label: 'About Goosly', url: '/public-roadmaps', isExternal: false },
    { label: 'Discover roadmaps', url: '/discover', isExternal: false },
    { label: 'Why is Goosly free?', url: 'https://wiki.jexo.io/space/GOOS/848265236', isExternal: true },
    { label: 'Media & Press', url: 'https://wiki.jexo.io/space/GOOS/848592897/Media+%26+Press', isExternal: true },
];

const supportArray = [
    { label: 'Documentation', url: 'https://wiki.jexo.io/space/GOOS', isExternal: true },
    { label: 'FAQs', url: 'https://wiki.jexo.io/space/GOOS/848429058', isExternal: true },
    { label: 'Support portal', url: 'https://jexo.atlassian.net/servicedesk/customer/portal/9', isExternal: true },
    { label: 'Uptime and status', url: 'https://status.jexo.io/', isExternal: true },
];

const companyArray = [
    { label: 'Jexo team', url: 'https://jexo.io/meet-the-team/', isExternal: true },
    { label: 'Jexo Atlassian apps', url: 'https://marketplace.atlassian.com/vendors/1215681/jexo', isExternal: true },
    { label: 'Blog', url: '/blog', isExternal: false },
    { label: 'Why we built Goosly', url: 'https://jexo.io/blog/goosly-free-timeline-roadmap-tool', isExternal: true },
];

export default function Footer() {
    const footerBgColor = useColorModeValue('gray.100', 'gray.700');

    return (
        <Flex direction="column" width="100%" mt="120px">
            <Flex
                h="4px"
                bgGradient={`linear(to-r, ${DEFAULT_ROADMAP_GRADIENT.start}, ${DEFAULT_ROADMAP_GRADIENT.end}, ${DEFAULT_ROADMAP_GRADIENT.green})`}
            />
            <Box as="footer" bgColor={footerBgColor} px="10" pt="6">
                <Grid templateColumns={{ base: '1f1', lg: 'repeat(4, 1fr)' }} mb={{ base: 8, lg: 16 }}>
                    <Flex direction="column" pr={{ base: 0, lg: 20 }}>
                        <LogoFooter />
                        <Text as="p" mt="4" mb="5" fontStyle="italic" fontSize="md">
                            {`Create free, simple and shareable timeline roadmaps for everything.`}
                        </Text>
                        <Flex justify="space-between" mb="5">
                            <Link href="https://twitter.com/Jexoio" isExternal>
                                <Icon as={AiOutlineTwitter} w={6} h={6} />
                            </Link>
                            <Link href="https://www.instagram.com/jexo.io/" isExternal>
                                <Icon as={AiOutlineInstagram} w={6} h={6} />
                            </Link>
                            <Link href="https://www.linkedin.com/company/jexo/" isExternal>
                                <Icon as={AiFillLinkedin} w={6} h={6} />
                            </Link>
                            <Link href="https://www.facebook.com/jexoio/" isExternal>
                                <Icon as={AiFillFacebook} w={6} h={6} />
                            </Link>
                            <Link href="https://www.youtube.com/channel/UCHrBiGSYBuUpePwwVIo8C4w" isExternal>
                                <Icon as={AiFillYoutube} w={6} h={6} />
                            </Link>
                        </Flex>
                    </Flex>
                    <Box mb="4">
                        <FooterMenu title="Pages" footerData={pagesArray} />
                    </Box>
                    <Box mb="4">
                        <FooterMenu title="Support" footerData={supportArray} />
                    </Box>
                    <Box mb="4">
                        <FooterMenu title="Company" footerData={companyArray} />
                    </Box>
                </Grid>
                <Flex direction={{ base: 'column', lg: 'row' }} justify={{ lg: 'space-between' }} pb={3}>
                    <Text as="p" width={{ lg: '70%' }} fontSize="md" mb={4}>
                        Built with 💚 and 🍕 by{' '}
                        <Link href="https://jexo.io" isExternal>
                            jexo.io
                        </Link>
                    </Text>
                    <Flex width={{ lg: '30%' }} justify="space-between">
                        <FooterLink title="Privacy" href="/privacy" />
                        <FooterLink title="Cookies" href="/cookie" />
                        <FooterLink title="Terms" href="/terms" />
                        <FooterLink title="DPA" href="/dpa" />
                        <Text fontSize="sm" fontFamily="body" lineHeight="4">
                            {`©️ 2022, jexo.io`}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
}
