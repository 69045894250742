import { Text, Link, Flex } from '@chakra-ui/react';

interface FooterMenuProps {
    title: string;
    footerData: FooterData[];
}

interface FooterData {
    label: string;
    url: string;
    isExternal: boolean;
}

export default function FooterMenu({ title, footerData }: FooterMenuProps) {
    return (
        <Flex direction="column" my={2} gap={2}>
            <Text as="small" fontWeight="bold" fontSize="sm">
                {title}
            </Text>
            {footerData.map((data) => {
                return (
                    <Link key={data.label} href={data.url} isExternal={data.isExternal}>
                        <Text as="p" fontSize="md">
                            {data.label}
                        </Text>
                    </Link>
                );
            })}
        </Flex>
    );
}
